import React from 'react'
import styled from 'styled-components'
import HeroImage from 'components/hero-image'
import Category from 'components/product-squares/stihl-category-box'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Content from 'components/content'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'

const stihlCategories = [
  {
    name: 'Augers and Drills',
    alt: 'STIHL Augers and Drills',
    link: '/stihl/augers-and-drills/',
    imageId: 'augersDrillsImage',
  },
  {
    name: 'Batteries and Accessories',
    alt: 'STIHL Batteries and Accessories',
    link: '/stihl/batteries-and-accessories/',
    imageId: 'batteriesImage',
  },
  {
    name: 'Bed Redefiners',
    alt: 'STIHL Bed Redefiners',
    link: '/stihl/bed-redefiner/',
    imageId: 'bedRedefinersImage',
  },
  {
    name: 'Blowers & Shredder Vacs',
    alt: 'STIHL Blowers & Shredder Vacs',
    link: '/stihl/blowers-and-shredder-vacs/',
    imageId: 'blowersImage',
  },
  {
    name: 'Chain Saws',
    alt: 'STIHL Chain Saws',
    link: '/stihl/chain-saws/',
    imageId: 'chainsawsImage',
  },
  {
    name: 'Concrete Cutters',
    alt: 'STIHL Concrete Cutters',
    link: '/stihl/concrete-cutters/',
    imageId: 'concreteCuttersImage',
  },
  {
    name: 'Cut-Off Machines',
    alt: 'STIHL Cut-Off Machines',
    link: '/stihl/cut-off-machines/',
    imageId: 'cutOffMachinesImage',
  },
  {
    name: 'Edgers',
    alt: 'STIHL Edgers',
    link: '/stihl/edgers/',
    imageId: 'edgersImage',
  },
  {
    name: 'Hand Tools',
    alt: 'STIHL Hand Tools',
    link: '/stihl/hand-tools/',
    imageId: 'handToolsImage',
  },
  {
    name: 'Hedge Trimmers',
    alt: 'STIHL Hedge Trimmers',
    link: '/stihl/hedge-trimmers/',
    imageId: 'hedgeTrimmersImage',
  },
  {
    name: 'Lawn Mowers',
    alt: 'STIHL Lawn Mowers',
    link: '/stihl/lawn-mowers/',
    imageId: 'lawnMowersImage',
  },
  {
    name: 'Multi-Task Tools',
    alt: 'STIHL Multi-Task Tools',
    link: '/stihl/multi-task-tools/',
    imageId: 'kombisystemImage',
  },
  {
    name: 'Pole Pruners',
    alt: 'STIHL Pole Pruners',
    link: '/stihl/pole-pruners/',
    imageId: 'polePrunersImage',
  },
  {
    name: 'Pressure Washers',
    alt: 'STIHL Pressure Washers',
    link: '/stihl/pressure-washers/',
    imageId: 'pressureWashersImage',
  },
  {
    name: 'Sprayers',
    alt: 'STIHL Sprayers',
    link: '/stihl/sprayers',
    imageId: 'backpackSprayersImage',
  },
  {
    name: 'Trimmers & Brushcutters',
    alt: 'STIHL Trimmers & Brushcutters',
    link: '/stihl/trimmers-and-brushcutters/',
    imageId: 'trimmersImage',
  },
  {
    name: 'Wet/Dry Vacuums',
    alt: 'STIHL Wet/Dry Vacuums',
    link: '/stihl/wet-dry-vacuums/',
    imageId: 'vacuumsImage',
  },
]

const StihlCategoriesPage = ({ data }) => {
  const { heroImage } = data
  const categoryItems = stihlCategories.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>STIHL Categories | Hutson Inc</title>
        <meta
          name='description'
          content='Hutson offers a wide range of STIHL products, including chainsaws, trimmers, brush cutters, hedge trimmers, pressure washers, blowers, and so much more.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'STIHL',
                'item': 'https://www.hutsoninc.com/stihl/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Categories',
                'item': 'https://www.hutsoninc.com/stihl/categories/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroImage title='STIHL Categories' image={heroImage} />

      <Content>
        <Grid>
          {categoryItems.map(category => (
            <CategoryContainer key={category.imageId}>
              <Link to={`${category.link}`}>
                <Category src={category.image} name={category.name} />
              </Link>
            </CategoryContainer>
          ))}
        </Grid>
      </Content>
    </Layout>
  )
}

const Grid = styled.div`
  @media (min-width: 600px) {
    ${clearfix}
  }
`

const CategoryContainer = styled.div`
  a {
    color: #000;
    text-decoration: none;
  }

  @media (min-width: 600px) {
    ${column('1/2')}
  }

  @media (min-width: 800px) {
    ${column('1/3')}
  }

  @media (min-width: 1200px) {
    ${column('1/5')}
  }
`

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "stihl/stihl-categories-bg.jpg" }) {
      ...FullWidthImage
    }
    augersDrillsImage: file(relativePath: { eq: "stihl/augers-and-drills/wood-boring-drill.jpg" }) {
      ...StihlCategoryBoxImage
    }
    batteriesImage: file(relativePath: { eq: "stihl/batteries-and-accessories/chargers.jpg" }) {
      ...StihlCategoryBoxImage
    }
    bedRedefinersImage: file(
      relativePath: { eq: "stihl/bed-redefiner/professional-bed-redefiner.jpg" }
    ) {
      ...StihlCategoryBoxImage
    }
    blowersImage: file(
      relativePath: { eq: "stihl/blowers-and-shredder-vacs/homeowner-blowers.jpg" }
    ) {
      ...StihlCategoryBoxImage
    }
    chainsawsImage: file(relativePath: { eq: "stihl/chain-saws.jpg" }) {
      ...StihlCategoryBoxImage
    }
    concreteCuttersImage: file(
      relativePath: { eq: "stihl/concrete-cutters/professional-concrete-cutters.jpg" }
    ) {
      ...StihlCategoryBoxImage
    }
    cutOffMachinesImage: file(
      relativePath: { eq: "stihl/cut-off-machines/professional-cut-off-machines.jpg" }
    ) {
      ...StihlCategoryBoxImage
    }
    edgersImage: file(relativePath: { eq: "stihl/edgers/homeowner-edger.jpg" }) {
      ...StihlCategoryBoxImage
    }
    handToolsImage: file(relativePath: { eq: "stihl/hand-tools/battery-hand-tools.jpg" }) {
      ...StihlCategoryBoxImage
    }
    hedgeTrimmersImage: file(relativePath: { eq: "stihl/hedge-trimmers.jpg" }) {
      ...StihlCategoryBoxImage
    }
    lawnMowersImage: file(relativePath: { eq: "stihl/lawn-mowers/homeowner-lawn-mowers.jpg" }) {
      ...StihlCategoryBoxImage
    }
    kombisystemImage: file(
      relativePath: { eq: "stihl/multi-task-tools/homeowner-kombisystem.jpg" }
    ) {
      ...StihlCategoryBoxImage
    }
    polePrunersImage: file(relativePath: { eq: "stihl/pole-pruners.jpg" }) {
      ...StihlCategoryBoxImage
    }
    pressureWashersImage: file(relativePath: { eq: "stihl/pressure-washers.jpg" }) {
      ...StihlCategoryBoxImage
    }
    backpackSprayersImage: file(relativePath: { eq: "stihl/sprayers/backpack-sprayers.jpg" }) {
      ...StihlCategoryBoxImage
    }
    trimmersImage: file(relativePath: { eq: "stihl/trimmers-and-brushcutters.jpg" }) {
      ...StihlCategoryBoxImage
    }
    vacuumsImage: file(relativePath: { eq: "stihl/wet-dry-vacuums/homeowner-vacuum.jpg" }) {
      ...StihlCategoryBoxImage
    }
  }
`

export default StihlCategoriesPage
