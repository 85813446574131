import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const StihlCategoryBox = ({ name, src }) => {
  return (
    <Container>
      <StyledImg image={src.childImageSharp.gatsbyImageData} alt={`STIHL ${name}`} />
      <Hr />
      <Title>{name}</Title>
    </Container>
  )
}

StihlCategoryBox.propTypes = {
  name: PropTypes.string.isRequired,
  src: PropTypes.object.isRequired,
}

const Container = styled.div`
  display: block;
  margin: 0 auto;
  padding: 15px 0;
  text-align: center;

  :hover {
    text-decoration: underline;
  }

  @media (min-width: 800px) {
    padding: 0 0 40px;
  }
`

const StyledImg = styled(GatsbyImage)`
  max-width: 300px;
  width: 100%;
`

const Hr = styled.hr`
  background-color: #387c2c;
  border: none;
  color: #387c2c;
  height: 2px;
  margin-top: 15px;
  width: 75px;
`

const Title = styled.h3`
  margin: 15px auto;
  text-align: center;
`

export const StihlCategoryBoxImageFragment = graphql`
  fragment StihlCategoryBoxImage on File {
    childImageSharp {
      gatsbyImageData(width: 300)
    }
  }
`

export default StihlCategoryBox
